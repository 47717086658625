import React from 'react'
import "./Partners.css"
import PartnersImg from "./refinery7.jpg"

function Partners() {
    return (
        <div className="partners__main">
            <div className="partners__inner">
                <h3>Clients & Partnerships</h3>
            </div>
            <div className="partners">
                <img src={PartnersImg} className="partners__img"/>
                <h5 className="partners__txt">
                <br/>
                <br/>
                <br/>
                In conjunction with our partners locally and internationally, we have been able to 
                deliver quality services to our Clients with the use of mainly indigenous personnel.
                 Working towards controlling a percentage of the market share, we are committed to 
                 delivering world class services, and ensuring good return on investments.
                 <br/>
                 We enjoy good relationship with our partners, and we are proud to be associated with them.
                <br/> 
                <br/>
                THANK YOU PARTNERS!

                </h5>
            </div>
        </div>
    )
}

export default Partners
