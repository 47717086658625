import React, {useState} from "react"
import {NavLink} from "react-router-dom"
import Logo from "./logo.png"
import Refinery from "./refinery3.jpg"
import Refinery2 from "./refinery2.jpg"
import Refinery6 from "./refinery6.jpg"
import Refinery8 from "./refinery8.jpg"
import Refinery9 from "./refinery9.jpg"
import Carousel from 'react-bootstrap/Carousel';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarRateIcon from '@material-ui/icons/StarRate';

import "./Banner.css"

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

// You can also pass an optional settings object
// below listed default settings
AOS.init({
	// Global settings:
	disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
	startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
	initClassName: 'aos-init', // class applied after initialization
	animatedClassName: 'aos-animate', // class applied on animation
	useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
	disableMutationObserver: false, // disables automatic mutations' detections (advanced)
	debounceDelay: 20, // the delay on debounce used while resizing window (advanced)
	throttleDelay: 9, // the delay on throttle used while scrolling the page (advanced)
	
  
	// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
	offset: 200, // offset (in px) from the original trigger point
	delay: 20, // values from 0 to 3000, with step 50ms
	duration: 1700, // values from 0 to 3000, with step 50ms
	easing: 'ease-in-out', // default easing for AOS animations
	once: false, // whether animation should happen only once - while scrolling down
	mirror: false, // whether elements should animate out while scrolling past them
	anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  
  });



const data = [
    {
     image: Refinery8, 
     caption:"NIOCCAN",
     description:"ELECTRIC WIRELINE SERVICES"
    },
       {
      image: Refinery, 
      caption:"NIOCCAN",
      description: "SLICKLINE SERVICES"
     },
      {
       image: Refinery2, 
       caption:"NIOCCAN",
       description:"PRESSURE CONTROL SERVICES"
      },
     {
      image: Refinery9, 
      caption:"NIOCCAN",
      description:"WELLHEAD MAINTAINANCE"
     },
      {
       image: Refinery6, 
       caption:"NIOCCAN",
       description:"HAULAGE SERVICES"
      } 
  ]


// function Banner() {
// 	return(
// 		<div className="banner">
	
// 			<div className="banner_img">
// 			<h1>OIL WELL<br/>INTERVENTION</h1>

// 			</div>
// 		</div>
// )
// }
// export default Banner;



function Banner() {

    const [index, setIndex] = useState(0);
    const [openMenu, setOpenMenu] = useState(true)
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    function scrollToTop() {
        // Scroll to top logic
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
      }

    
    
    

    return (
        <div classname="carousel__inner">
            <div className="top__header">
            <NavLink to= "/home" activeStyle={{ color: "white", padding: "0px 0px"}} onClick = {scrollToTop}>
                <img className="logo" src={Logo}/>
                </NavLink>

                     <div className="open__menu">
                            <MenuIcon color="primary" onClick={() => setOpenMenu(false)}/>
                        </div>

                <nav className={`header__nav ${openMenu && 'menu__toggle'}`}>
                        <div className="close__menuu">
                            <CloseIcon color="primary" onClick={() => setOpenMenu(true)}/>
                        </div>

                        <div className="values">
                    <p className="focus">
                        FOCUS
                    </p>
                    <p className="execution">
                        EXECUTION
                    </p>
                    <p className="respect">
                        RESPECT
                    </p>
                </div>

                    <div className="the-nav-links">
                        <NavLink to= "/home" onClick = {scrollToTop} className="first-selected-link selectedLink" style={{textDecoration: "none"}} activeStyle={{ color: "white", padding: "0px 0px"}}>
                            <p className="link home__icon__link"> HOME </p>
                        </NavLink>
                        <NavLink to= "/about" onClick = {scrollToTop} className="selectedLink" style={{textDecoration: "none"}} activeStyle={{backgroundColor: "black", color: "white", borderBottom: "2px solid white"}}>
                            <p className="link"> ABOUT US </p>
                        </NavLink>
                        <NavLink to= "/services" onClick = {scrollToTop} className="selectedLink" style={{textDecoration: "none"}} activeStyle={{backgroundColor: "black", color: "white", borderBottom: "2px solid white"}}>
                            <p className="link"> OUR SERVICES </p>
                        </NavLink>
                    </div>

                        <span className="header__span">
                <a className="contact__link" href="mailto:info@nioccan.com">
                    <p className="header__p"><MailOutlineIcon fontSize="small"/>info@nioccan.com</p>
                </a>
                <a className="contact__link" href="mailto:sales@nioccan.com">
                    <p className="header__p"><MailOutlineIcon fontSize= "small"/>sales@nioccan.com</p>
                </a>
                </span>
                        {/* <NavLink to= "/services" activeClassName="selectedLink" activeStyle={{backgroundColor: "black", color: "white"}}>
                            <p className="link"> CONTACT US </p>
                        </NavLink> */}
                        </nav>
            </div>
            

            
            <div className="carousel-item-margin carousel__item active" >
            
            <Carousel slide Carousel-fade activeIndex={index} onSelect={handleSelect}>

            
            {data.map((slide, i) => {
                return (
                <Carousel.Item>   
                        
                <img
                className="banner__img"
                src={slide.image}
                alt="slider image"
                />


                
                
                <Carousel.Caption className="banner__txt">
                    {/* <div className="banner__header__links">
                    
                        <div  className="logoooo" href="/home">
                        <NavLink to= "/home"  activeStyle={{ textDecoration: 'none'}}>
                            <img className="logo" src={Logo}/>
                        </NavLink>
                        </div>
                        <nav>

                        <NavLink to= "/home" activeClassName="selectedLink" activeStyle={{ textDecoration: 'none', backgroundColor: "black", color: "white", borderRadius: "18px", padding: "0px 0px"}}>
                            <p className="link home__icon__link"> Home </p>
                        </NavLink>
                        <NavLink to= "/about" activeClassName="selectedLink" activeStyle={{ textDecoration: 'none', backgroundColor: "black", color: "white", borderRadius: "18px"}}>
                            <p className="link"> About </p>
                        </NavLink>
                        <NavLink to= "/services" activeClassName="selectedLink" activeStyle={{ textDecoration: 'none', backgroundColor: "black", color: "white", borderRadius: "18px"}}>
                            <p className="link"> Services </p>
                        </NavLink>
           
                        </nav>
                    </div> */}
            
                <div data-aos="zoom-in" className="banner__btn">
                     {/* <h3>{slide.caption}</h3> */}
                     <p className="description">{slide.description}</p>

                     <a className="contact__link" href="mailto:bdd@nioccan.com">
                     <Button className="btn" variant="contained" color="primary">Contact Us</Button>
                     </a>
                     


                </div>
                <div>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
                )
            })}
            
            </Carousel>

            </div>
        </div>
    )
}

export default Banner
