import React from 'react'
import "./Contact.css"
import Img from "./bg.jpg"
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import WhatsApp from '@material-ui/icons/WhatsApp';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

import LocationOnIcon from '@material-ui/icons/LocationOn';


import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

// You can also pass an optional settings object
// below listed default settings
AOS.init({
	// Global settings:
	disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
	startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
	initClassName: 'aos-init', // class applied after initialization
	animatedClassName: 'aos-animate', // class applied on animation
	useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
	disableMutationObserver: false, // disables automatic mutations' detections (advanced)
	debounceDelay: 10, // the delay on debounce used while resizing window (advanced)
	throttleDelay: 70, // the delay on throttle used while scrolling the page (advanced)
	
  
	// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
	offset: 200, // offset (in px) from the original trigger point
	delay: 10, // values from 0 to 3000, with step 50ms
	duration: 500, // values from 0 to 3000, with step 50ms
	easing: 'ease-in-out', // default easing for AOS animations
	once: false, // whether animation should happen only once - while scrolling down
	mirror: false, // whether elements should animate out while scrolling past them
	anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  
  });

function Contact() {

    // const d = document.write(new Date().getFullYear());
    // console.log(d);

    return (
        <div className="contact__section">

                <div class="contact__main">

                    <div className="contact__us">
                            
                            <div className="card">                        
                                <div className="contact__card1">
                                    <div className="partners__inner">
                                        <h6 className="clients__header">CLIENTS & PARTNERSHIPS</h6>
                                    </div>
                                    <div className="partners">
                                        <h5 className="partners__txt">
                                        
                                        In conjunction with our partners locally and internationally, we have been able to 
                                        deliver quality services to our Clients with the use of mainly indigenous personnel.
                                        Working towards controlling a percentage of the market share, we are committed to 
                                        delivering world class services, and ensuring good return on investments.
                                        <br/>
                                        We enjoy good relationship with our partners, and we are proud to be associated with them.
                                        <br/> 
                                        <br/>
                                        THANK YOU PARTNERS!

                                        </h5>
                                    </div>
                                </div>


                                <div className="contact__card2 contact__card22">
                                <div className="clients__header"> OUR SERVICES
                                </div>
                                <div className="partners__txt">
                                <PlaylistAddCheckIcon color="primary"/> Electric Wireline Services
                                <br/>
                                <PlaylistAddCheckIcon color="primary"/> Slickline Services
                                <br/>
                                <PlaylistAddCheckIcon color="primary"/> Pressure Control Services
                                <br/>
                                <PlaylistAddCheckIcon color="primary"/> Wellhead Maintainance Services
                                <br/>
                                <PlaylistAddCheckIcon color="primary"/> Coiled Tubing Services
                                <br/>
                                <PlaylistAddCheckIcon color="primary"/> Haulage Services
                                <br/>
                                </div>
                                </div>
                                

                            
                                <div className="contact__card2">
                                <div className="clients__header">
                                HEAD OFFICE                               
                                </div>
                                <div className="partners__txt">
                                Plot 474, Trans-Amadi Road, Trans-Amadi Industrial Layout,
                                Port Harcourt – NIGERIA
                                <br/>
                                <a className="contact__link" href="mailto:info@nioccan.com">
                                    <MailOutlineIcon/> E-mail: info@nioccan.com
                                </a>

                                <br/>
                               <a className="contact__link" href="mailto:sales@nioccan.com">
                                <MailOutlineIcon/> E-mail: sales@nioccan.com
                                </a>
                                <br/>
                                <a className="contact__link" href="tel:+234-805-958-8167">
                                <PhoneIcon/> Phone: +234-805-958-8167
                                </a>
                                </div>
                                  
                                </div>




                            </div>
                    </div>

                </div>


                <div className="copyright">
                        <h6 className="copyright__h6">© Copyright 2023 NIOCCAN, All Right Reserved</h6>
                </div>
       
           
            
        </div>
    )
}

export default Contact
