import React, {useState} from 'react'
import "./Service.css"
import Button from "react-bootstrap/Button"
import Ewireline from "./e-wireline-skid.jpg"
import Links from "./Links.js"


function EWireline() {

    return (           
                <div className='individual-service-page'>

                      <div>
                            <Links/>
                      </div>   

                      <div className='service-header-background'>
                        <div>
                          <h1 className='service'>E-LINE SERVICES</h1>  
                        </div>  
                      </div>             

                      <div className="servicee_card">
                          
                      
                            
                            <div className='service-individual'>
                                <div className='service-each-the-left' >
                                    <div className="individual-service-image">
                                        <img src={Ewireline} className="img modal__img"/>
                                    </div>
                                    <p className="modal__p">
                                    We provide complete range of downhole well-logging services. Our Tubing Conveyed Perforating
                                    (TCP) services, specializes in deep-hole and high-pressure perforation. With precision rated equipment,
                                    high levels of expertise and High Level of Safety (HLS), this makes us the best choice for TCP
                                    applications.
                                    Our Wireline personnel expertise in fabrication and maintenance will help you achieve maximum
                                    lifetime production from your well and give timely solutions to your target goals.
                                    <br/>
                                    <br/>
                                    <Button variant="primary" href="mailto:info.nioccan@gmail.com">Contact Us</Button>

                                    
                                    </p>
                                </div>

                                  <p className='service-each-the-right'>

                                  

                                    <ul className="coreService">

                                    <h4 className='core-service-title'>Core Services:</h4>

                                    <li>Production Logging</li>
                                    <li> Cement Bond Logging</li>
                                    <li>Perforating Services</li>
                                    <li>Pipe Recovery</li>
                                    <li>Setting Services</li>
                                    <li>Gamma Ray/Neutron Correlation Logging</li>
                                    <li>Complete line of Tubing Conveyed Perforating (TCP) Systems Available</li>
                                    <li>Experienced in Vertical and Horizontal Completions</li>
                                    <li>Wide Array of Coiled Tubing Gun Systems</li>
                                    <li>Full line of associated completion hardware</li>

                                    </ul>
                                    

                                    </p>
                                    
                                    


                                    </div>
                                </div>
                  </div>    
 )
}

export default EWireline;