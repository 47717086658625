import React, {useState} from 'react'
import "./Service.css"
import Button from "react-bootstrap/Button"
import WellHeadUnit from "./wellhead.jpg"
import Modal from 'react-bootstrap/Modal'
import Links from "./Links.js"


function WellHead() {

  return (           
              <div className='individual-service-page'>

                    <div>
                          <Links/>
                    </div>   

                    <div className='service-header-background'>
                      <div>
                        <h1 className='service'>WELLHEAD MAINTAINANCE SERVICES</h1>  
                      </div>  
                    </div>             

                    <div className="servicee_card">
                        
                    
                          
                          <div className='service-individual'>
                              <div className='service-each-the-left' >
                                  <div className="individual-service-image">
                                      <img src={WellHeadUnit} className="img modal__img"/>
                                  </div>
                                  <p className="modal__p">
                                  Our services include routine inspections, maintenance and
                                 repair of surface Wellheads, as well as Wellhead Completions. Our expertise work covers various Wellheads,
                                 Chokes & Valves from Cameron, Vecto Gray & TechnipFMC. We also carry out comprehensive valve refurbishments.
                                With the support of our technical partners, we supply quality Wellhead systems to
                                 suit all casing size requirements - to full API
                                specifications. We provide conventional stack-up Wellhead systems, full bore and slim hole 
                                multi-bowl Wellhead systems as well
                                as systems for gas and oil well applications. These surface Wellhead Systems
                                can be used onshore and offshore.

                                  <br/>
                                  <br/>
                                  <Button variant="primary" href="mailto:info.nioccan@gmail.com">Contact Us</Button>

                                  
                                  </p>
                              </div>

                                <p className='service-each-the-right'>

                                

                                  <ul className="coreService">

                                  <h4 className='core-service-title'>Core Services:</h4>

                                  <li>
                                    First and Second Line Maintenance (Routine & Non-Routine Maintenance).
                                    </li>
                                    <li>
                                    Repair, Refurbishment and Installation of Wellhead Equipment & Accessories
                                    </li>
                                    <li>
                                    Supply, Installation and Maintenance of OEM Wellhead Equipment & Accessories
                                    </li>
                                    <li>
                                    Tool Rentals: Lubricators, X-overs, Drilling Spools/Adapter, Running & Retrieving
                                    </li>
                                    <li>
                                    Tool for Seal Assembly & Wear Bushing
                                    </li>
                                    <li>
                                    Xmas tree valve greasing and valve pressure testing
                                    </li>
                                    <li>
                                    Repair, Refurbishment and Installation of Chokes & Valves (all brands).
                                    </li>
                                    <li>
                                    Pneumatic/Hydraulic Bolting Services
                                    </li>
                                    <li>
                                    Supply of Valves (Gate, Ball, Globe, Check, Butterfly, Plug) & Actuators

                                    </li>

                                  </ul>
                                  

                                  </p>
                                  
                                  


                                  </div>
                              </div>
                </div>    
)
}

export default WellHead;