// new branch commentdfdadfad


import React from "react"
import {BrowserRouter, Route, Switch} from "react-router-dom";

import './App.css';
import Home from "./Homepage"
import AboutPage from "./AboutPage";
import ServicePage from './ServicePage'
import Partners from "./Partners"
import Contact from "./Contact"
import EWireline from "./S-ElectricWirelineModal"
import Slickline from "./S-SlicklineModal"
import PressureControl from "./S-PressureControlModal"
import WellHead from "./S-WellheadMaintanaceModal"
import CoiledTubing from "./S-CoiledTubingModal"
import Haulage from "./S-HaulageModal"



function App() {
  return (
    <BrowserRouter >

    <div className="App">

          <Switch>
          <Route path="/"component={Home} exact />
            <Route path="/home"component={Home} exact />
            <Route path="/contact"component={Contact} />
            <Route path="/services"component={ServicePage} />
            <Route path="/about"component={AboutPage} />
            <Route path="/partners"component={Partners} />
            <Route path="/ewireline"component={EWireline} />
            <Route path="/slickline"component={Slickline} />
            <Route path="/perssurecontrol"component={PressureControl} />
            <Route path="/wellhead"component={WellHead} />
            <Route path="/coiledtubing"component={CoiledTubing} />
            <Route path="/haulage"component={Haulage} />


          </Switch>
        
    </div>
    </BrowserRouter>

  );
}

//dfjhdfjhdfjkdfd
export default App;

