import React from 'react'
import "./ServicePage.css"
import "./Service.css"
import Links from "./Links.js"
import {Link} from "react-router-dom"
import Contact from "./Contact"
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import Ewireline from "./e-wireline-skid.jpg"
import Button from "react-bootstrap/Button"
import WellHeadUnit from "./wellhead.jpg"
import SlicklineImg from "./slicklineunit.png"
import PressureControlUnit from "./pressurecontrol.jpg"
import Refinery5 from "./refinery5.jpg"
import CoiledTubingUnit from "./coiledtubing.jpg"



import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

// You can also pass an optional settings object
// below listed default settings
AOS.init({
	// Global settings:
	disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
	startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
	initClassName: 'aos-init', // class applied after initialization
	animatedClassName: 'aos-animate', // class applied on animation
	useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
	disableMutationObserver: false, // disables automatic mutations' detections (advanced)
	debounceDelay: 20, // the delay on debounce used while resizing window (advanced)
	throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
	
  
	// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
	offset: 200, // offset (in px) from the original trigger point
	delay: 20, // values from 0 to 3000, with step 50ms
	duration: 1700, // values from 0 to 3000, with step 50ms
	easing: 'ease-in-out', // default easing for AOS animations
	once: false, // whether animation should happen only once - while scrolling down
	mirror: false, // whether elements should animate out while scrolling past them
	anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  
  });


function Service() {

	function scrollToTop() {
		// Scroll to top logic
		window.scrollTo({
		  top: 0,
		  behavior: "smooth"
		})
	  }

    return (
		
		<div className="service__section">

			<div>
				<Links/>

			</div>

			
			{/* <SnowFall
                // Changes the snowflake color
                color="blue"
                // Applied to the canvas element
                style={{}}
                // Controls the number of snowflakes that are created (default 150)
                snowflakeCount={500}
            /> */}

			<div className="service__main">			
									
				<div className="service__inner">
					<div className='service-header-background service-service-page'>
                      <div>
                        <h1 className='service'>SERVICES</h1>  
                      </div>  
                    </div> 
					
										<div className='services-each'>
											
											<div className='service-each-text'>
												<p className='service-title'>E-LINE SERVICES</p>
												<div className='service-breif'>   
													<p className="breif__service__details">  We provide complete range of downhole well-logging services. Our Tubing Conveyed Perforating
														(TCP) services, specializes in deep-hole and high-pressure perforation...</p>
														
														<Link onClick = {scrollToTop} to="/ewireline" style={{textDecoration: "none", color: "white"}}>
														<Button className="service-each-btn">
														LEARN MORE
														</Button>
														</Link>
												</div>
											</div> 
											<img className="service-page-each-img" src={Ewireline}></img>
										</div>

										<div className='services-each'>
											
											<div className='service-each-text'>
												<p className='service-title'>SLICKLINE SERVICES</p>
												<div className='service-breif'>   
													<p className="breif__service__details">  We provide an extensive range of Slickline Services from basic Completion Intervention to more advanced services. Our Slickline unit has a compact design for...</p>
													<Link onClick = {scrollToTop} to="/slickline" style={{textDecoration: "none", color: "white"}}>
														<Button className="service-each-btn">
														LEARN MORE
														</Button>
													</Link>
												</div>
											</div> 
											<img className="service-page-each-img" src={SlicklineImg}></img>
										</div>

										<div className='services-each'>
											
											<div className='service-each-text'>
												<p className='service-title'>PRESSURE CONTROL SERVICES</p>
												<div className='service-breif'>   
													<p className="breif__service__details">Pressure Control is critical for safe entry into and exit from the well, regardless of the operation performed or the well effluent used. Maintenance of the optimal...</p>
													<Link onClick = {scrollToTop} to="/perssurecontrol" style={{textDecoration: "none", color: "white"}}>
														<Button className="service-each-btn">
														LEARN MORE
														</Button>
													</Link>
												</div>
											</div> 
											<img className="service-page-each-img" src={PressureControlUnit}></img>
										</div>

										<div className='services-each'>
											
											<div className='service-each-text'>
												<p className='service-title'>WELLHEAD MAINTAINANCE SERVICES</p>
												<div className='service-breif'>   
													<p className="breif__service__details">Our services include routine inspections, maintenance and repair of surface Wellheads, as well as Wellhead Completions. Our expertise work covers...</p>
													<Link onClick = {scrollToTop} to="/wellhead" style={{textDecoration: "none", color: "white"}}>
														<Button className="service-each-btn">
														LEARN MORE
														</Button>
														</Link>
												</div>
											</div> 
											<img className="service-page-each-img" src={WellHeadUnit}></img>
										</div>

										<div className='services-each'>
											
											<div className='service-each-text'>
												<p className='service-title'>COILED TUBING SERVICES</p>
												<div className='service-breif'>   
													<p className="breif__service__details">With the support of our technical partners and personnel expertise, NIOCCAN offers up-to-date CT services. Coiled Tubing services with its metal...</p>
													<Link onClick = {scrollToTop} to="/coiledtubing" style={{textDecoration: "none", color: "white"}}>
														<Button className="service-each-btn">
														LEARN MORE
														</Button>
													</Link>
												</div>
											</div> 
											<img className="service-page-each-img" src={CoiledTubingUnit}></img>
										</div>

										<div className='services-each'>
											
											<div className='service-each-text'>
												<p className='service-title'>HAULAGE SERVICES</p>
												<div className='service-breif'>   
													<p className="breif__service__details">We carryout Logistics Services with a precise focused on addressing our Clients supply chain requirements. NIOCCAN Haulage Services is powered with...</p>
													<Link onClick = {scrollToTop} to="/haulage" style={{textDecoration: "none", color: "white"}}>
														<Button className="service-each-btn">
														LEARN MORE
														</Button>
														</Link>
												</div>
											</div> 
											<img className="service-page-each-img" src={Refinery5}></img>
										</div>
				

				</div>
						


						
			</div>
			<div>
				<Contact/>
			</div>


		</div>
       
    )
}

export default Service