import React, {useState} from 'react'
import "./Service.css"
import Button from "react-bootstrap/Button"
import CoiledTubingUnit from "./coiledtubing.jpg"
import Modal from 'react-bootstrap/Modal'
import SnowFall from "react-snowfall"
import Links from "./Links.js"


function CoiledTubing() {

  return (           
              <div className='individual-service-page'>

                    <div>
                          <Links/>
                    </div>   

                    <div className='service-header-background'>
                      <div>
                        <h1 className='service'>COILED TUBING SERVICES</h1>  
                      </div>  
                    </div>             

                    <div className="servicee_card">
                        
                    
                          
                          <div className='service-individual'>
                              <div className='service-each-the-left' >
                                  <div className="individual-service-image">
                                      <img src={CoiledTubingUnit} className="img modal__img"/>
                                  </div>
                                  <p className="modal__p">
                                  With the support of our technical partners and personnel expertise, NIOCCAN offers up-to-date CT services. Coiled Tubing services with its metal pipe, spooled on a large reel and comprised of complete set of equipment necessary to perform standard continuous-length tubing operations in the oilfield industry, is what we give Our Clients. When wellbore fluids reduce well production, Coiled Tubing can deliver nitrogen that reduces the hydrostatic column, while the lightened column allows formation fluid to unload from the reservoir and lifts the fluid along with light debris.
CT Services adds great value and time saving to Clients Well Operations, by eliminating use of workover rigs and reducing volumes of treatment fluid.

                                  <br/>
                                  <br/>
                                  <Button variant="primary" href="mailto:info.nioccan@gmail.com">Contact Us</Button>

                                  
                                  </p>
                              </div>

                                <p className='service-each-the-right'>

                                

                                  <ul className="coreService">

                                  <h4 className='core-service-title'>Core Services:</h4>

                                  <li>
                                    Matrixstimulation
                                    </li>
                                    <li>
                                    Scale Removal
                                    </li>
                                    <li>
                                    Logging
                                    </li>
                                    <li>
                                    Perforation
                                    </li>
                                    <li>
                                    Nitrogen Jet Lift
                                    </li>
                                    <li>
                                    Sand Control
                                    </li>
                                    <li>
                                    Milling and Drilling
                                    </li>
                                    <li>
                                    Cementing
                                    </li>
                                    <li>
                                    Well Circulation
                                    </li>
                                    <li>
                                    Mechanical Isolation
                                    </li>

                                  </ul>
                                  

                                  </p>
                                  
                                  


                                  </div>
                              </div>
                </div>    
)
}

export default CoiledTubing;
