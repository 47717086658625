import React from 'react'
import "./AboutPage.css"
import SnowFall from "react-snowfall"
import Links from "./Links"
import InfoIcon from '@material-ui/icons/Info';
import AboutImg from "./about-img.png"
import Contact from "./Contact"
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import StarRateIcon from '@material-ui/icons/StarRate';



import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

// You can also pass an optional settings object
// below listed default settings
AOS.init({
	// Global settings:
	disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
	startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
	initClassName: 'aos-init', // class applied after initialization
	animatedClassName: 'aos-animate', // class applied on animation
	useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
	disableMutationObserver: false, // disables automatic mutations' detections (advanced)
	debounceDelay: 10, // the delay on debounce used while resizing window (advanced)
	throttleDelay: 10, // the delay on throttle used while scrolling the page (advanced)
	
  
	// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
	offset: 200, // offset (in px) from the original trigger point
	delay: 2, // values from 0 to 3000, with step 50ms
	duration: 500, // values from 0 to 3000, with step 50ms
	easing: 'ease-in-out', // default easing for AOS animations
	once: false, // whether animation should happen only once - while scrolling down
	mirror: false, // whether elements should animate out while scrolling past them
	anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  
  });

function About2() {
    return (

        <div className="about__section">
            <div>
            <Links/>

            </div>
            <div className=' about-page-div-header'>
                      <div>
                        <h1 className='aboutt'>ABOUT US</h1>  
                      </div>  
                    </div> 
        <div data-aos="fade-in" className="about__main">
            
           
            <div className="about__name">

            <div className="about__overview">
            <SnowFall
                // Changes the snowflake color
                color="white"
                // Applied to the canvas element
                style={{}}
                // Controls the number of snowflakes that are created (default 150)
                snowflakeCount={10}
            />
             <div className="about__description">
            <PlaylistAddCheckIcon/>WHO WE ARE
            </div>

            <p data-aos="fade-up" className="about__txt">NIOCCAN Support Services Limited is an Oilfield company that provides Onshore and Offshore technical services to the Oil & Gas Industry in Nigeria. NIOCCAN as an indigenous Company with a professional and excellent engineering competency, covers many areas in Well Intervention and Completion Services.
            <br/>
            <br/>
            NIOCCAN since its incorporation has expanded its capabilities to include a wide range of services, such as Electric Wireline Services (E-Line), Slickline Services, Wellhead Maintenance and Supply Services, Pressure Pumping Services, Surveys, Well Completion Services, Haulage Services, Supply of Construction materials and more.
            <br/>
            <br/>
            Our High Level of Safety (HLS) that is always needed, for a zero incident, makes us standout in the industry, with quality and excellence in our services and operations.</p>

            
            </div>
            <div data-aos="zoom-in" className="about__img" >
                <img className="imgg" src={AboutImg}/>
            </div>
            

            </div>
        </div>
        {/* <div className="about__img">
            <img className="image__about" src={AboutImg} />
        </div> */}
        <div className="about__chunk">

            <div data-aos="fade-right" className="each">
            <SnowFall
                // Changes the snowflake color
                color="white"
                // Applied to the canvas element
                style={{}}
                // Controls the number of snowflakes that are created (default 150)
                snowflakeCount={2}
            />
                <h4  className="each__header first">
                    <PlaylistAddCheckIcon/>OUR MISSION
                </h4>
                <p  className="each__content">
                To give excellent and outstanding services to our Clients, 
                exceeding their expectations that brings respect to our company.
                </p>
            </div>

            <div data-aos="fade-left" className="each">
            <SnowFall
                // Changes the snowflake color
                color="white"
                // Applied to the canvas element
                style={{}}
                // Controls the number of snowflakes that are created (default 150)
                snowflakeCount={2}
            />
                <h4 className="each__header">
                    <PlaylistAddCheckIcon/>OUR VISION
                </h4>
                <p className="each__content">
                To be the leading company in all areas of business we cover 
                with new innovations and worldwide recognition.
                </p>
            </div>

            <div data-aos="fade-right" className="each">
            <SnowFall
                // Changes the snowflake color
                color="white"
                // Applied to the canvas element
                style={{}}
                // Controls the number of snowflakes that are created (default 150)
                snowflakeCount={2}
            />
                <h4  className="each__header">
                <PlaylistAddCheckIcon/>OUR VALUES
                </h4>
                <p  className="each__content">
                <span className="values__span">FOCUS </span> - We put our passion and teamwork spirit to make sure our tasks are completely executed.
                <br/>
                <span className="values__span">EXECUTION</span> - With our expertise we close out all tasks with a High Level of Safety (HLS), 
                with the expected results, and put smiles on the faces of our Clients.
                <br/><span className="values__span">RESPECT</span> - We treat all our Clients, employees, members, partners and people
                 with respect and integrity to maintain their trust.

                </p>
            </div>

            <div data-aos="fade-left" className="each">
            <SnowFall
                // Changes the snowflake color
                color="white"
                // Applied to the canvas element
                style={{}}
                // Controls the number of snowflakes that are created (default 150)
                snowflakeCount={2}
            />
                <h4  className="each__header">
                <PlaylistAddCheckIcon/>QHSE POLICY
                </h4>
                <p  className="each__content">
                NIOCCAN is committed to provide excellent services and products that meet and exceeds 
                Client’s expectations, complying with statutory and regulatory requirements in the industry, 
                and also improving continuously in our QHSE Management System.


                </p>
            </div>

            <div data-aos="fade-right" className="each">
            <SnowFall
                // Changes the snowflake color
                color="white"
                // Applied to the canvas element
                style={{}}
                // Controls the number of snowflakes that are created (default 250)
                snowflakeCount={2}
            />
                <h4  className="each__header">
                <PlaylistAddCheckIcon/>OUR TEAM
                </h4>
                <p  className="each__content">
                Our multi-discipline team comprises of competent and experienced hands, with global
                 recognition and professional qualifications to give the best to our Clients, always in 
                 the services we render.

                </p>
            </div>
            

        </div>

        <div>
            <Contact/>
        </div>
            
        </div>
    )
}

export default About2
