import './Home.css';
import Banner from "./Banner"
import About2 from "./About2";
import Service from './Service'
import Partners from "./Partners"
import Contact from "./Contact"
import ScrollTop from "./ScrollTop"

function Home() {
  return (
    <div className="App">  
      <Banner />
      {/* <About2/> */}

      <Service />
      {/* <About /> */}
      {/* <Partners /> */}
      <Contact />
    <ScrollTop/>
    </div>
  );
}

export default Home;