import React, {useState} from 'react'
import "./Service.css"
// import Button from '@material-ui/core/Button';
import Button from "react-bootstrap/Button"

// import Button from '@material-ui/core/Button';
import Refinery5 from "./refinery5.jpg"
import Modal from 'react-bootstrap/Modal'
import Links from "./Links.js"


function Haulage() {

    return (           
                <div className='individual-service-page'>

                      <div>
                            <Links/>
                      </div>   

                      <div className='service-header-background'>
                        <div>
                          <h1 className='service'>HAULAGE SERVICES</h1>  
                        </div>  
                      </div>             

                      <div className="servicee_card">
                          
                      
                            
                            <div className='service-individual'>
                                <div className='service-each-the-left' >
                                    <div className="individual-service-image">
                                        <img src={Refinery5} className="img modal__img"/>
                                    </div>
                                    <p className="modal__p">
                                    We carryout Logistics Services with a precise focused on addressing our Clients supply chain requirements. NIOCCAN Haulage Services is powered with fleet of sound vehicles, that ensure integrity and safety, on the movement of products. Our Trucks can deliver Clients goods and materials, in volume and weight, to any point in Nigeria, and our well trained and qualified Drivers, with regular maintained trucks, supported with technology, makes us close out the job seamlessly. With professionalism, security, safety, technology, and insurance, this will make you always use NIOCCAN for all your haulage activities.
                                    <br/>
                                    <br/>
                                    <Button variant="primary" href="mailto:info.nioccan@gmail.com">Contact Us</Button>

                                    
                                    </p>
                                </div>

                                  <p className='service-each-the-right'>

                                  

                                    <ul className="coreService">

                                    <h4 className='core-service-title'>Core Services:</h4>

                                    <li>Full Truck Load (FTL)</li>
                                    <li>Just In Time (JIT) Haulage</li>
                                    <li>Edicated Haulage Services</li>
                                    <li>On-Demand Haulage Services</li>
                                    <li>Pre-Scheduled Delivery Services</li>
                                    <li>Reverse Logistics</li>
                                    <li>General Supply Chain Movement </li>

                                    </ul>
                                    

                                    </p>
                                    
                                    


                                    </div>
                                </div>
                  </div>    
 )
}

export default Haulage;