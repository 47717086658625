import React, {useState} from 'react'
import {NavLink} from "react-router-dom"
import Logo from "./logo.png"
import "./Links.css"
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';


function Links() {

    const [openMenu, setOpenMenu] = useState(true)

    function scrollToTop() {
        // Scroll to top logic
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
      }



    return (
        <div className="header__links">
                    

                   
                    <NavLink onClick = {scrollToTop} to="/home">
                        
                            <img className="link__logo" src={Logo}/>
                    
                    </NavLink>

                    <div className="open__menu">
                            <MenuIcon onClick={() => setOpenMenu(false)}/>
                        </div>
                       
                    <nav className={`header__nav2 header__nav__stack ${openMenu && 'menu__toggle'}`}>
                        <div className="close__menu">
                            <CloseIcon color="secondary" onClick={() => setOpenMenu(true)}/>
                        </div>

                        <NavLink onClick = {scrollToTop} to= "/home" className="selectedLink2" style={{textDecoration: 'none'}}  activeStyle={{ textDecoration: 'none', color: "white", borderTop: '2px solid white', padding:"0px 0px"}}>
                            <p className="linkk first__link"> HOME </p>
                        </NavLink>
                        <NavLink onClick = {scrollToTop} to= "/about" className="selectedLink2" style={{textDecoration: 'none'}} activeStyle={{ textDecoration: 'none', color: "white", borderTop: '2px solid white', padding: "5px 0px 5px 0px"}}>
                            <p className="linkk"> ABOUT US</p>
                        </NavLink>
                        <NavLink onClick = {scrollToTop} to= "/services" className="selectedLink2" style={{textDecoration: 'none'}} activeStyle={{ textDecoration: 'none', color: "white", borderTop: '2px solid white', padding: "5px 0px 5px 0px"}}>
                            <p className="linkk"> OUR SERVICES </p>
                        </NavLink>

                        {/* <NavLink to= "/about" activeStyle={{ textDecoration: 'none', backgroundColor: "black", color: "white", padding: "5px 5px 0px 0px"}}>
                            <p className="link" href="/about"> Contact Us</p>
                        </NavLink> */}
                    
                        
                        </nav>
                    </div>
    )
}

export default Links
