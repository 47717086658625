import React, {useState} from 'react'
import "./Service.css"
import Button from "react-bootstrap/Button"
import SlicklineImg from "./slicklineunit.png"
import Modal from 'react-bootstrap/Modal'
import Links from "./Links.js"


function Slickline() {

    return (           
                <div className='individual-service-page'>

                      <div>
                            <Links/>
                      </div>   

                      <div className='service-header-background'>
                        <div>
                          <h1 className='service'>SLICKLINE SERVICES</h1>  
                        </div>  
                      </div>             

                      <div className="servicee_card">
                          
                      
                            
                            <div className='service-individual'>
                                <div className='service-each-the-left' >
                                    <div className="individual-service-image">
                                        <img src={SlicklineImg} className="img modal__img"/>
                                    </div>
                                    <p className="modal__p">
                                    We provide an extensive range of Slickline Services from basic Completion Intervention to more
                                advanced services. Our Slickline unit has a compact design for remote service capabilities and is field
                                proven to be efficient and reliable.
                                We have the personnel expertise and our equipment offers a wide array of Slickline Operations.

                                    <br/>
                                    <br/>
                                    <Button variant="primary" href="mailto:info.nioccan@gmail.com">Contact Us</Button>

                                    
                                    </p>
                                </div>

                                  <p className='service-each-the-right slickline-service-right'>

                                  

                                    <ul className="coreService">

                                    <h4 className='core-service-title'>Core Services:</h4>

                                    <li>Fishing</li>
                                <li>Sand Bailing</li>
                                <li>Swabbing</li>
                                <li>Zone Change</li>
                                <li>Sand Bailing</li>
                                <li>Wax Cutting</li>
                                <li>Gas Lift Operations</li>
                                <li>Tubing Leak Testing</li>
                                <li>Setting Bridge Plugs & Packers</li> 
                                <li>Safety Devices</li>
                                <li>Tubing Perforation</li>
                                <li>Pressure and Temperature Surveys (with DHSIT)</li>
                                <li>Work Over/Completion Tools</li>
                                <li>Pulling & Setting of Sub-surfaces</li>
                                <li>Tubing Leak Detection</li>
                                <li>Gas Lift Design/Optimization Services</li>
                                <li>Electronic Memory Gauge Rental</li>
                                <li>BHP Analysis & Interpretation</li>
                                <li>Down-Hole Shut-In Tool Services</li>
                                <li>Repair, Redressing, Testing and Calibration of SSV Pilots and Down-Hole-Safety Valves</li>
                                <li>Pressure Testing and Calibration of Gas Lift Valves & Chemical Injection Valves</li>

                                    </ul>
                                    

                                    </p>
                                    
                                    


                                    </div>
                                </div>
                  </div>    
 )
}

export default Slickline;