import React, {useState} from 'react'
import "./Service.css"
// import Button from '@material-ui/core/Button';
import Button from "react-bootstrap/Button"
import PressureControlUnit from "./pressurecontrol.jpg"
import Modal from 'react-bootstrap/Modal'
import Links from "./Links.js"


function PressureControl() {

    return (           
                <div className='individual-service-page'>

                      <div>
                            <Links/>
                      </div>   

                      <div className='service-header-background'>
                        <div>
                          <h1 className='service'>PRESSURE CONTROL SERVICES</h1>  
                        </div>  
                      </div>             

                      <div className="servicee_card">
                          
                      
                            
                            <div className='service-individual'>
                                <div className='pressure-control-flex service-each-the-left' >
                                    <div className="pressure-control-img-flex individual-service-image">
                                        <img src={PressureControlUnit} className="img modal__img"/>
                                    </div>
                                    <p className="pressure-control-modal-flex modal__p">
                                    Pressure Control is critical for safe entry into and exit from the well,
                                 regardless of the operation performed or the well effluent used. 
                                 Maintenance of the optimal pressure level inside a wellbore when any 
                                 Well Intervention Operations is performed is key for a well, and as such,
                                  we provide Pressure Control Equipment in a variety of sizes and ratings, and
                                   is selected according to the particular need of the job and also as requested
                                    by the Clients. We have Pressure Control Equipment of 3,000 psi, 5,000 psi, 10,000
                                     psi and 15,000 psi, and the equipment is designed with the intent to withstand
                                      arduous conditions and thus use stringent quality standards to perform its tasks.
                                    <br/>
                                    <br/>
                                    <Button variant="primary" href="mailto:info.nioccan@gmail.com">Contact Us</Button>

                                    
                                    </p>
                                </div>                                    
                                    


                                    </div>
                                </div>
                  </div>    
 )
}

export default PressureControl;