import React, {useState, useEffect} from "react";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import "./ScrollTop.css"


function ScrollToTheTop () {

    const [visible, setVisible] = useState(false);


    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 500) {
                setVisible(true)
            } else setVisible(false)
        })
    
    }, [])
      



function scrollToTop() {
  // Scroll to top logic
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  })
}

return (
                <div className={`btn__init ${visible && 'btn__scroll'}`} onClick = {scrollToTop}  id="scrollToTopBtn">
                <ExpandLessIcon fontSize="large"/> 
                </div>
    )

}

export default ScrollToTheTop;



//             OR USING DOM

// function ScrollToTheTop () {


//     useEffect(() => {
//         window.addEventListener("scroll", () => {
//             if (window.scrollY > 100) {
//             document.getElementById("scrollToTopBtn").classList.add("btn__scroll");
//         }
//         if  (window.scrollY < 100) {
//             document.getElementById("scrollToTopBtn").classList.remove("btn__scroll");

//         }
//     }, [])
// })
      



// function scrollToTop() {
//   // Scroll to top logic
//   window.scrollTo({
//     top: 0,
//     behavior: "smooth"
//   })
// }

// return (
//                 <div className="btn__init" onClick = {scrollToTop}  id="scrollToTopBtn">
//                 <ExpandLessIcon fontSize="large"/>
//                 </div>
//     )

// }




// export default ScrollToTheTop;